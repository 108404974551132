import React from "react";
import ChannelLogo from "../components/ChannelLogo";
import "../styles.css";

const PageLayout = ({ children, type }) => (
  <div className="page-layout">
    <div className="page-content">
      <ChannelLogo />
      {!!!type && <h1>Welcome to Mozrest Subscription Service</h1>}
      {type === "meta" && <h1>Subscribe to Meta</h1>}
      {type === "michelin" && <h1>Subscribe to The MICHELIN Guide</h1>}
      {type === "listing" && <h1>Subscribe to Listing & Review</h1>}
      {type === "smartchannel" && <h1>Subscribe to Smart Channel Manager and Booking Channel(s)</h1>}
      {children}
    </div>
  </div>
);

export default PageLayout;
