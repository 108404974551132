import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to set Authorization token globally
export const setAuthToken = (token) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

// Function to fetch the token
export const getToken = async (uid, hash) => {
  try {
    const response = await api.post("/v2/token", {
      grant_type: "hash_credentials",
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
      userId: uid,
      hash: hash,
    });
    return response.data.access_token;
  } catch (error) {
    console.error("Error fetching token:", error);
    throw error;
  }
};

export const fetchUserDetails = async () => {
  try {
    const response = await api.get("/user-me");
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

// Function to fetch commercial conditions
export const fetchCommercialConditions = async (partnerId, type) => {
  try {
    const response = await api.get(
      `/opportunity/${getTypeId(type)}/finder?rms=${partnerId}&country=all`
    );
    return response.data.commercialConditions;
  } catch (error) {
    console.error("Error fetching commercial conditions:", error);
    throw error;
  }
};

const getTypeId = (type) => {
  if (type === "meta") return '60e5a3cab8e5c33e185dy323';
  if (type === "michelin") return '61829b96aad315397161c12b';
  return null;
};

export const fetchSmartChannelCommercialConditions = async (partnerId) => {
  try {
    const response = await api.get(
      `/opportunity?filters[product]=smartchannel&filters[rms]=${partnerId}`
    );
    const opportunity = response.data ? response.data.data[0] : [];

    console.log("opportunity", opportunity)

    const commercialConditions = await api.get(
      `/opportunity/${opportunity.id}/commercial-condition?filters[country]=ALL`
    );

    console.log("commercialConditions", commercialConditions)


    return commercialConditions.data ? commercialConditions.data.data[0] : [];
  } catch (error) {
    console.error("Error fetching commercial conditions:", error);
    throw error;
  }
};

export const fetchListingCommercialConditions = async (partnerId) => {
  try {
    const response = await api.get(
      `/opportunity?filters[product]=listing&filters[rms]=${partnerId}`
    );
    const opportunity = response.data ? response.data.data[0] : [];

    const commercialConditions = await api.get(
      `/opportunity/${opportunity.id}/commercial-condition?filters[country]=ALL`
    );

    return commercialConditions.data ? commercialConditions.data.data[0] : [];
  } catch (error) {
    console.error("Error fetching commercial conditions:", error);
    throw error;
  }
};

export default api;
